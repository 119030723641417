import './App.css'
import { Route, Switch } from 'react-router-dom'
import { Typography, Grid, Container } from '@mui/material'
import Menu from './components/Menu'
import CV from './components/CV'
import Art from './components/Art'
import Contact from './components/Contact'

function App() {
  return (
    <Container>
      <Menu />
        <Grid
          container
          justifyContent={'center'}
          xs={12}
          sx={{ marginTop: '2em', marginBottom: '2.5em' }}
        >
          <Switch>
            <Route exact path='/' component={Art} />
            <Route exact path='/cv' component={CV} />
            <Route exact path='/art' component={Art} />
            <Route exact path='/contact' component={Contact} />
          </Switch>
        </Grid>
      <Typography
        variant='body1'
        component='p'
        sx={{
          textAlign: 'center',
          fontSize: '0.6em',
          marginBottom: '1em',
          marginTop: '5em',
        }}
      >
        &#169; Sara Erkers, made by{' '}
        <a href='https://erkers.dev' target={'blank'}>
          erkers.dev
        </a>
      </Typography>
    </Container>
  )
}

export default App
