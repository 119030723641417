import '../App.css'
import { AppBar, Toolbar, Button, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

const Menu = () => {
  return (
    <AppBar
      position='sticky'
      color='inherit'
      sx={{
        background: 'linear-gradient(45deg, #FF8E53 30%, #FE6B8B 90%)',
        minHeight: '5em',
        marginTop: '1em',
      }}
    >
      <Toolbar sx={{ height: '5em' }}>
        <Typography variant='h5' component='h5' sx={{ flexGrow: 1 }}>
          <Link to='/' className='siteName'>
            <h5>Sara Erkers</h5>
          </Link>
        </Typography>
        
        <Link to='/art'>
          <Button
            id='art'
            sx={{ 
              color: '#555', 
              height: '3em', 
              marginRight: '5px' 
            }}
          >
            KONST
          </Button>
        </Link>
        
        <Link to='/cv'>
          <Button
            id='cv'
            sx={{
              color: '#555',
              height: '3em',
              marginRight: '5px',
            }}
          >
            CV
          </Button>
        </Link>
        
        <Link to='/contact'>
          <Button
            id='contact'
            sx={{ 
              color: '#555', 
              height: '3em', 
              marginRight: '5px' 
            }}
          >
            KONTAKT
          </Button>
        </Link>
      </Toolbar>
    </AppBar>
  )
}

export default Menu
