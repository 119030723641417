import { useState } from 'react'
import { ImageListItem, ImageListItemBar, Modal } from '@mui/material'

const Image = ({ image }: { image: any }) => {
  const [imageRadius, setImageRadius] = useState('5px')
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <ImageListItem>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <ImageListItem
          key={image.src}
          style={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            maxWidth: '90vw',
            maxHeight: '90vh',
            minWidth: 400,
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#EFEFEF',
            boxShadow: '24',
            borderRadius: 3,
          }}
        >
          <img
            src={image.src}
            srcSet={image.src}
            alt={image.title}
            loading='lazy'
            style={{ maxHeight: '90vh', maxWidth: '90vw' }}
          />
          <ImageListItemBar
            title={image.title}
            subtitle={image.subtitle}
            classes={{
              subtitle: 'image-subtitle-style',
              title: 'image-title-style',
            }}
          />
        </ImageListItem>
      </Modal>
      <img
        style={{
          borderRadius: imageRadius,
          transition: 'border-radius 0.5s',
          cursor: 'pointer',
        }}
        src={image.src}
        srcSet={image.src}
        alt={image.title}
        loading='lazy'
        onMouseEnter={() => {
          setImageRadius('40px')
        }}
        onMouseLeave={() => {
          setImageRadius('5px')
        }}
        onClick={handleOpen}
      />
    </ImageListItem>
  )
}
export default Image
