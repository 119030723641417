import { Container } from '@mui/material'
import Year from './Year'
// 2023
// Juni. Projektledare ”TExtilt samtidskonst symposium” Wallénhallen Leksand. tillsammans med Anna Linnea Liljeholm

// September. Separatutställning ”Ropen skalla: Rovor åt alla!”Länsmuseet Gävleborg, Gävle. 
const About = () => {
  return (
    <Container>
      <h1>CV</h1>
      <Year year={'2023'} />
      <ul>
        <li>
          Producent & projektledare ”Textilt samtidskonstsymposium”,
          Wallénhallen Leksand. Tillsammans med Anna Linnea Liljeholm.
        </li>
        <li>
          Separatutställning ”Ropen skalla: Rovor åt alla!”. Länsmuseet
          Gävleborg, Gävle.
        </li>
      </ul>
      <Year year={'2022'} />
      <ul>
        <li>
          Arkiv Gävleborg. Undersökande konstnärligt arbete utifrån ett arkivs
          samlingar.
        </li>
        <li>
          Boken ”Oss Gräsätare Emellan” presenteras på Otilia Adelborgs museum i
          Gagnef, Dalarna.
        </li>
      </ul>

      <Year year={'2021'} />
      <ul>
        <li>Bank Galleri, Söderhamn</li>
        <li>
          Trolldalen Gagnef, Utställning med Gabriel Jonsson med stöd av Region
          Dalarna.
        </li>
        <li>Point of View, Steneby Konsthall.</li>
        <li>Projektstöd Konstnärsnämnden, "Oss Gräsätare Emellan".</li>
      </ul>

      <Year year={'2020'} />
      <ul>
        <li>
          Meken "Någonting är på gång - I händerna på konstnären", Smedjebacken.
        </li>
        <li>Not Quite "Vulgaris", med Elin Flognman, Fengersfors</li>
        <li>Platina galleri, med Elin Flognman, Stockholm.</li>
      </ul>

      <Year year={'2019'} />
      <ul>
        <li>Borlänge Modern "Herbivorerna", Borlänge.</li>
        <li>
          Blå Lågan "Den som samlat mest bös på hög när den dör vinner",
          Borlänge.
        </li>
        <li>Platsen Landskapet Konsten, Sundborn.</li>
        <li>Sågmyra Arts and Fashion festival, Sågmyra.</li>
        <li>Jubilarens Val, Meken Smedjebacken.</li>
      </ul>

      <Year year={'2018'} />
      <ul>
        <li>Konstnärsnämnden, 2 årigt arbetsstipendium.</li>
        <li>Galleri Svarta Gran ”Sällskap”, Borlänge.</li>
        <li>
          The 6th Riga International Textile and Fibre Art Triennial. Riga,
          Lettland.
        </li>
      </ul>

      <Year year={'2017'} />
      <ul>
        <li>Dalakonst17 - Dalarnas museum, Falun.</li>
        <li>Konst åt alla, Idre.</li>
      </ul>

      <Year year={'2016'} />
      <ul>
        <li>Sím gallery. Reykjavik, Island.</li>
        <li>Sím residency, Juli. Reykjavik, Island.</li>
      </ul>

      <Year year={'2015'} />
      <ul>
        <li>Konstnärsnämnden, 1-årigt arbetsstipendium.</li>
        <li>Visual arts för festivalen ”Geggan”, Svedala.</li>
        <li>Visual Arts for ”Koloni”-sommarfestival, Göteborg.</li>
      </ul>

      <Year year={'2014'} />
      <ul>
        <li>
          Emotional Hangover. Golddigger Productions. Samarbete med Jannine
          Rivel, koreograf och dansare. Leksand/Göteborg.
        </li>
      </ul>

      <Year year={'2013'} />
      <ul>
        <li>
          ”Noas Ark”. Scenografi och kostym till opera uppsatt på Konserthuset i
          Göteborg och kulturhuset på Dal, Mellerud.
        </li>
        <li>
          ”Ett ansikte” Scenografi och kostym-assistent, för nyopera-ensembeln
          Dråpera, Göteborgs Operan.
        </li>
        <li>”Reminiscens” remfabriken i Gårda, grupputställning. Göteborg.</li>
      </ul>

      <Year year={'2012'} />
      <ul>
        <li>Göteborgs domkyrka, textil grupputställning.</li>
      </ul>

      <Year year={'2011'} />
      <ul>
        <li>
          Sjöfartsmuseet Akvariet ”Drömmen om ett annat Göteborg”. Göteborg.
        </li>
        <li>
          Performance på båten ”Älvsnabben”, i samarbete med dansare från
          Göteborgsoperans danskompani. Koreografi Toby Kassell. Göteborg.
        </li>
        <li>
          Kaunas Textile Biennial, grupputställning med HDK. Kaunas, Litauen.
        </li>
      </ul>

      <Year year={'2010'} />
      <ul>
        <li>
          Pusterviksteatern. Kostym till performance med dansare från
          Göteborgsoperans danskompani.
        </li>
      </ul>

      <h2 style={{ marginTop: '3em' }}>Utbildning</h2>
      <Year year={'2010 - 2012'} />
      <ul>
        <li>
          Göteborgs Universitet/Högskolan för design och konsthantverk HDK –
          Textil konst. MFA.
        </li>
      </ul>

      <Year year={'2011'} />
      <ul>
        <li>Göteborgs Universitet/Akademi Valand – Urban Sound, sommarkurs.</li>
      </ul>

      <Year year={'2009'} />
      <ul>
        <li>Textilhögskolan i Borås – Kurs i digital modeteckning.</li>
      </ul>

      <Year year={'2005 - 2008'} />
      <ul>
        <li>
          Göteborgs Universitet/Högskolan för design och konsthantverk HDK vid
          STENEBY – Textil Kläder Formgivning. BFA.
        </li>
      </ul>

      <Year year={'2007'} />
      <ul>
        <li>
          Iceland Academy of The Arts Reykjavik –Fashion Design. Utbytesprogram.
        </li>
      </ul>

      <Year year={'2003 - 2004'} />
      <ul>
        <li>Företagarnas Folkhögskola Leksand –Textil vävning.</li>
      </ul>
    </Container>
  )
}

export default About
