import { ImageList, Container, Grid } from '@mui/material'
import Image from './Image'

const grassEatersImages = [
  {
    src: './images/grasatare/framsida.opti.webp',
    title: 'Oss gräsätare emellan',
    subtitle:
      'Konst och idé: Sara Erkers. Grafisk formgivning: Joakim Karlsson. Foto: Marcus Dineen. Mat: Lisa Boberg. Bok producerad med stöd av Konstnärsnämnden. 2022.',
  },
  {
    src: './images/grasatare/purjo.opti.webp',
    title: "Åh purjo min purjo / Let's talk about leek baby",
    subtitle: 'Broderi 140 x 215cm, 2020. Foto: Marcus Dineen.',
  },
  {
    src: './images/grasatare/beet.opti.webp',
    title: 'Ropen skalla: Kaninmat åt alla! No.1',
    subtitle: '2020. Foto: Marcus Dineen.',
  },
  {
    src: './images/grasatare/broccoli.opti.webp',
    title: 'Great Greens',
    subtitle: 'Broderi 260 x 250cm, 2018. Foto: Marcus Dineen.',
  },
  {
    src: './images/grasatare/alien.opti.webp',
    title: 'My friend the alien',
    subtitle: 'Broderi 140 x 180cm, 2021. Foto: Marcus Dineen.',
  },
  {
    src: './images/grasatare/knifvur.opti.webp',
    title: 'Ropen skalla: Kaninmat åt alla! No.2',
    subtitle:
      'Broderi och målande applikationer 126 x 280cm, 2020. Foto: Marcus Dineen.',
  },
  {
    src: './images/grasatare/rodbeta.opti.webp',
    title: 'Ropen skalla: Kaninmat åt alla! No.2',
    subtitle:
      'Broderi och målande applikationer 126 x 280cm, 2020. Foto: Marcus Dineen.',
  },
  {
    src: './images/grasatare/paron.opti.webp',
    title: 'Pyrus Communis',
    subtitle: 'Broderi 67 x 63cm, 2016. Foto: Marcus Dineen.',
  },
  {
    src: './images/grasatare/tre.opti.webp',
    title: 'För det viktiga bordet',
    subtitle: 'Broderi, 2020 - 2021. Foto: Marcus Dineen.',
  },
]

const herbivoreImages: any[] = [
  {
    src: './images/herbivorerna/herbivorerna1.opti.webp',
    title: 'Daucus Carota',
    subtitle: 'Broderi, 2016',
  },
  {
    src: './images/herbivorerna/herbivorerna2.opti.webp',
    title: "Åh purjo min purjo / Let's talk about leek baby",
    subtitle:
      'Broderi 140 x 215cm, 2020. Utställningsbild Not Quite Fengersfors, 2020.',
  },
  {
    src: './images/herbivorerna/herbivorerna7.opti.webp',
    title: 'Apium graveloens – When life gives you celery',
    subtitle: 'Detalj, 2018',
  },
  {
    src: './images/herbivorerna/herbivorerna4.opti.webp',
    title: 'Reintroducing: Salvation!',
    subtitle: 'Detalj, 2020',
  },
  {
    src: './images/herbivorerna/herbivorerna5.opti.webp',
    title: 'Ropen skalla: Kaninmat åt alla! No.2',
    subtitle:
      'Broderi och målande applikationer 126 x 280cm, 2020. Utställningsbild Meken Smedjebacken, 2020.',
  },
  {
    src: './images/herbivorerna/herbivorerna6.opti.webp',
    title: 'Apium graveloens – When life gives you celery',
    subtitle: 'Broderi 250 x 90cm, 2018. Utställningsbild BoMo Borlänge, 2019.',
  },
  {
    src: './images/herbivorerna/herbivorerna11.opti.webp',
    title: "Åh purjo min purjo / Let's talk about leek baby",
    subtitle: 'Detalj, 2020',
  },
  {
    src: './images/herbivorerna/herbivorerna10.opti.webp',
    title: 'Great Greens',
    subtitle: 'Detalj, 2018',
  },
  {
    src: './images/herbivorerna/herbivorerna3.opti.webp',
    title: 'Reintroducing: Salvation!',
    subtitle: 'Broderi, 2020. Utställningsbild Platina Stockholm, 2020.',
  },
  {
    src: './images/herbivorerna/herbivorerna9.opti.webp',
    title: 'Great Greens',
    subtitle:
      'Broderi 260 x 250cm, 2018. Utställningsbild Meken Smedjebacken, 2020.',
  },
]

const companyImages = [
  {
    src: './images/company/company1.opti.webp',
    title: 'Sällskap',
    subtitle: '2012',
  },
  {
    src: './images/company/company2.opti.webp',
    title: 'Sällskap',
    subtitle: '2012',
  },
  {
    src: './images/company/company3.opti.webp',
    title: 'Sällskap',
    subtitle: '2012',
  },
  {
    src: './images/company/company4.opti.webp',
    title: 'Sällskap',
    subtitle: '2012',
  },
]

const hangoverImages = [
  {
    src: './images/emotional/emotional1.opti.webp',
    title: 'Emotional Hangover',
    subtitle: '',
  },
  // {
  //   src: './images/emotional/emotional2.opti.webp',
  //   title: 'Emotional Hangover',
  //   subtitle: 'Foto: Sarah Schmidt',
  // },
  {
    src: './images/emotional/emotional3.opti.webp',
    title: 'Emotional Hangover',
    subtitle: 'Foto: Sarah Schmidt',
  },
  {
    src: './images/emotional/emotional4.opti.webp',
    title: 'Emotional Hangover',
    subtitle: 'Foto: Sarah Schmidt',
  },
]

const Art = () => {
  return (
    <Container>
      <h1>KONST</h1>
      <Grid>
        <h2>OSS GRÄSÄTARE EMELLAN</h2>
        <ImageList cols={4} variant={'masonry'} gap={10}>
          {grassEatersImages.map((image) => {
            return <Image image={image} />
          })}
        </ImageList>
        <p>{/* Description of book project? */}</p>
      </Grid>
      <Grid>
        <h2>HERBIVORERNA / THE HERBIVORES</h2>
        <ImageList cols={4} variant={'masonry'} gap={10}>
          {herbivoreImages.map((image) => {
            return <Image image={image} />
          })}
        </ImageList>
        <p>
          Herbivorerna är en samling broderade fanor och standar. Motiven är
          grönsaker. Vardagliga, vanliga och kanske lite tråkiga grönsaker.
          Herbivorerna är en protest mot vår fixering av snabba rastlösa fixar.
          En demonstration för moder jord, där de broderade grönsakerna är
          slagorden. Hos Herbivorerna hyllas värdet i grodden och mystiken i
          jorden. Herbivorerna är här för att stanna - Herbivorerna kommer ta
          över - Herbivorerna skriker högt ut och viskar lågmält i ditt öra:
          Grönsaker Grönsaker Grönsaker.
        </p>
      </Grid>
      <Grid>
        <h2>SÄLLSKAP</h2>
        <ImageList cols={2} variant={'masonry'} gap={10}>
          {companyImages.map((image) => {
            return <Image image={image} />
          })}
        </ImageList>
      </Grid>
      <Grid>
        <h2>EMOTIONAL HANGOVER</h2>
        <ImageList cols={2} variant={'masonry'} gap={10}>
          {hangoverImages.map((image) => {
            return <Image image={image} />
          })}
        </ImageList>
        <p>
          ”Ett rituellt ritande av rörelser i baksmällan av det glömda och gömda
          i ett flätande färgande av band som knyts och återknyts”
        </p>
        <p>
          Sara Erkers och Jannine Rivel bjöds in av curatorerna Cecilia Suhaid
          Gustafsson och Sarah Schmidt (GoldDigger Productions), för att inleda
          ett samarbete där de under två veckor undersöker gränserna och
          metoderna i deras konstnärskap.
        </p>
        <p>
          Under två veckors arbete med ”Exploration in Laboratory” bodde Erkers
          och Rivel på en gård i Ullvi, Dalarna. Där undersökte,
          experimenterande och laborerade de inom deras konstnärsområden i
          relation till platsen, samhället, familjen och byns identitet. Delar
          av ”Exploration in Laboratory” visades i anslutning till verket
          ”Emotional hangover”.
        </p>
        <p>
          Medfinansieras av Västra Götalandsregionens Kulturnämnd & Göteborgs
          Stads Kulturnämnd.
        </p>
      </Grid>
    </Container>
  )
}

export default Art
