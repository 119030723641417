import { Container, Grid } from '@mui/material'

const Contact = () => {
  return (
    <Container>
      <h1>KONTAKT</h1>
      <p>
        <a
          href='mailto:sara.erkers@gmail.com'
          target={'blank'}
          style={{ fontSize: '1.2em' }}
        >
          sara.erkers@gmail.com
        </a>
      </p>
      <p>
        <a
          href='https://instagram.com/sara_erkers'
          target={'blank'}
          style={{ fontSize: '1.2em' }}
        >
          instagram/sara_erkers
        </a>
      </p>
      <Grid xs display='flex' justifyContent='center' alignItems='center'>
        <img
          src='./images/contactsara.jpg'
          alt='Contact Sara'
          style={{
            height: '60%',
            width: '60%',
            borderRadius: '50%',
            marginTop: '2em',
            maxWidth: '500px',
            maxHeight: '500px',
          }}
        />
      </Grid>
    </Container>
  )
}
export default Contact
